import React, { useEffect, useState, useContext, useRef, useCallback } from "react";
import PropTypes from "prop-types";
import { useParams, useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

import { Helmet } from "react-helmet";
import { Canvas } from "@react-three/fiber";
import {
	useProgress,
	PerspectiveCamera,
	View // OrbitControls, CameraControls, TransformControls
} from "@react-three/drei";
import { preventOverflow } from "@popperjs/core";
import { openDB } from "idb";
import { FaCircle } from "react-icons/fa";
import { RiSignalWifiErrorLine } from "react-icons/ri";
import styles from "../../styleModules/simulationStyles.module.css";
import { AppContext } from "../UtilityFunctions/AppContext.js";
import { cleanResponse } from "../UtilityFunctions/cleanResponse.js";
import { sleep } from "../UtilityFunctions/sleep.js";
import useChatMessages from "../UtilityFunctions/useChatMessages.js";
import { useAxiosLimited } from "../UtilityFunctions/axiosRetry.js";
import { useDeviceSettings } from "../UtilityFunctions/useDeviceSettings.js";

import { CheckPermissions } from "../Setup/SetupElements/GetUserMedias.js";
import { ConfirmModal, SimEndedModal, EndingModal, SocketErrorModal, ResumeSimModal } from "../Setup/SetupElements/SetupModals.js";
import { SoftPopup } from "../SecondaryComponents/SoftPopup.js";
import { PresentationPopUp } from "../SecondaryComponents/PresentationPopUp.js";
import { LoadingSpinner } from "../SecondaryComponents/LoadingSpinner.jsx";
import { Character } from "../SimulationOld/Character.js";
import ToneMapping from "../SimulationOld/ToneMapping.js";
import HDR_SETUP from "../SimulationOld/HDR_Setup.js";
import { characterDict } from "../SimulationOld/characterDict.js";
import { SimControls } from "./SimControls.js";
import { SimFinished } from "../Setup/SimFinished.js";
import { useSocket, connectionStatusOptions } from "./UseSocket.js";
import useLiveData from "./UseLiveData.js";
import { UserVideoComponent } from "./UserVideoComponent";
import { useSpeechSynthesizer } from "../SecondaryComponents/useSpeechSynthesizer";
import { useAudioRecorder } from "../UtilityFunctions/useAudioRecorder.js";
import { validateString } from "./validateString.js";

import usePollingIntervals from "../UtilityFunctions/usePollingIntervals"; // Assuming you've placed the hook in a hooks folder
import { AnimationController } from "../SimulationOld/AnimationController.js";
import { useUploadManager } from "../UtilityFunctions/useUploadManager.js";

const visemeObject = {
	0: { sounds: ["Silence"], description: "The mouth position when viseme ID is 0" },
	1: { sounds: ["æ", "ə", "ʌ"], description: "The mouth position when viseme ID is 1" },
	2: { sounds: ["ɑ"], description: "The mouth position when viseme ID is 2" },
	3: { sounds: ["ɔ"], description: "The mouth position when viseme ID is 3" },
	4: { sounds: ["ɛ", "ʊ"], description: "The mouth position when viseme ID is 4" },
	5: { sounds: ["ɝ"], description: "The mouth position when viseme ID is 5" },
	6: { sounds: ["j", "i", "ɪ"], description: "The mouth position when viseme ID is 6" },
	7: { sounds: ["w", "u"], description: "The mouth position when viseme ID is 7" },
	8: { sounds: ["o"], description: "The mouth position when viseme ID is 8" },
	9: { sounds: ["aʊ"], description: "The mouth position when viseme ID is 9" },
	10: { sounds: ["ɔɪ"], description: "The mouth position when viseme ID is 10" },
	11: { sounds: ["aɪ"], description: "The mouth position when viseme ID is 11" },
	12: { sounds: ["h"], description: "The mouth position when viseme ID is 12" },
	13: { sounds: ["ɹ"], description: "The mouth position when viseme ID is 13" },
	14: { sounds: ["l"], description: "The mouth position when viseme ID is 14" },
	15: { sounds: ["s", "z"], description: "The mouth position when viseme ID is 15" },
	16: { sounds: ["ʃ", "tʃ", "dʒ", "ʒ"], description: "The mouth position when viseme ID is 16" },
	17: { sounds: ["ð"], description: "The mouth position when viseme ID is 17" },
	18: { sounds: ["f", "v"], description: "The mouth position when viseme ID is 18" },
	19: { sounds: ["d", "t", "n", "θ"], description: "The mouth position when viseme ID is 19" },
	20: { sounds: ["k", "g", "ŋ"], description: "The mouth position when viseme ID is 20" },
	21: { sounds: ["p", "b", "m"], description: "The mouth position when viseme ID is 21" }
};
const soundToVisemeMapping = {
	Silence: "SIL",
	æ: "A_E",
	ə: "A_E",
	ʌ: "A_E",
	ɑ: "Ah",
	ɔ: "Oh",
	ɛ: "EE",
	ʊ: "W_OO",
	ɝ: "Er",
	j: "EE",
	i: "EE",
	ɪ: "Ih",
	w: "W_OO",
	u: "W_OO",
	o: "Oh",
	aʊ: "W_OO",
	ɔɪ: "Oh",
	aɪ: "A_E",
	h: "SIL",
	ɹ: "R",
	l: "T_L_D",
	s: "S_Z",
	z: "S_Z",
	ʃ: "S_Z",
	tʃ: "CH_J",
	dʒ: "CH_J",
	ʒ: "S_Z",
	ð: "Th",
	f: "F_V",
	v: "F_V",
	d: "T_L_D",
	t: "T_L_D",
	n: "T_L_D",
	θ: "Th",
	k: "K_G",
	g: "K_G",
	ŋ: "K_G",
	p: "B_M_P",
	b: "B_M_P",
	m: "B_M_P"
};

function DebugComponent({ connectionStatus }) {
	// statusLabel, active, progress, errors, item, loaded, total
	return (
		connectionStatus === connectionStatusOptions.Disconnected && (
			<div className={styles.connectionIconContainer}>
				<RiSignalWifiErrorLine className={styles.connectionIcon} />
			</div>
		)
		// <div
		//     className={styles.simCharacterName}
		// >
		//     <div>
		//         {errors}
		//         {/* {item} */}
		//     </div>
		//     {loaded !== total &&
		//         <div>
		//             {loaded}/
		//             {total}
		//         </div>
		//     }
		//     <div>
		//         {connectionStatus}
		//     </div>
		//     {statusLabel}
		// </div>
	);
}
DebugComponent.propTypes = {
	connectionStatus: PropTypes.string.isRequired
	// statusLabel: PropTypes.string.isRequired,
	// active: PropTypes.bool.isRequired,
	// progress: PropTypes.number.isRequired,
	// errors: PropTypes.string.isRequired,
	// item: PropTypes.string.isRequired,
	// loaded: PropTypes.number.isRequired,
	// total: PropTypes.number.isRequired,
};
async function initDB() {
	return openDB("simulation", 1, {
		upgrade(db) {
			db.createObjectStore("simulation_db");
		}
	});
}

function SimulationStream({ setupType: propsetupType }) {
	const { axiosLimitedGet, axiosLimitedPost, axiosLimitedPut, axiosLimitedPatch, axiosLimitedDelete } = useAxiosLimited();
	const posthog = usePostHog();

	const { setupType, savedId, simId } = useParams();
	const setup_type = setupType ? setupType.toLowerCase() : propsetupType.toLowerCase();
	const {
		runTranslation,
		pauseSimRef,
		localUser,
		tenantName,
		user,
		showSidebar,
		setSidebarContent,
		setSidebarContentView,
		setShowSidebar,
		isMobileDevice,
		userLanguage,
		// activeSessionData,
		setActiveSessionData,
		translateDictText,
		performTranslation,
		performanceMode,
		setPerformanceMode,
		setupData
	} = useContext(AppContext);

	const [userMicText, setUserMicText] = useState("Start speaking");
	const [simTransitionText, setSimTransitionText] = useState("Waiting for the other person...");
	const activeSessionDataRef = useRef(null);
	const handleConnectCountRef = useRef(0);

	// const [performanceMode, setPerformanceMode] = useState(true);

	// Static Translation
	const LocalLanguageDict = {
		Thinking: "Thinking",
		TheCustomerIsJoining: "The customer is joining the call.",
		TheInterviewerIsJoining: "The interviewer is joining the call.",
		YourPartnerIsJoining: "Your partner is joining the call.",
		TheOtherPersonIsJoining: "The other person is joining the call.",
		TheCustomerIsApproaching: "The customer is approaching.",
		StartSpeaking: "Start speaking",
		Listening: "Listening",
		Paused: "Paused",
		AreYouThere: "Are you there?",
		FinishSpeaking: "Finish speaking to send or click the checkmark",
		WeArentDetectingMicrophone: "We aren't detecting any microphone input. Please check your microphone and refresh the page"
	};

	const [pageText, setPageText] = useState({
		...LocalLanguageDict,
		userMicTextTranslated: userMicText,
		simTransitionTextTranslated: simTransitionText
	});

	const [isTranslating, setIsTranslating] = useState(false);

	// Static Usage
	useEffect(() => {
		async function translateText() {
			setIsTranslating(true);
			await performTranslation(pageText, setPageText);
			setIsTranslating(false);
		}

		if (!isTranslating) {
			// console.log("Translating Sessions Dropdown");
			translateText();
			// console.log("pageText: ", pageText);
		}
	}, [userLanguage, pageText]);

	const { selectedDevices, GetMedia, mediaInitialized } = useDeviceSettings();

	const url = process.env.REACT_APP_BACKEND_STATIC_URL;
	const liveUrl = process.env.REACT_APP_BACKEND_STATIC_URL;
	const CanvasRef = useRef(null);

	const { updateChatMessages, updateMediaControlsAllowed } = useChatMessages({ OnPauseEvent: pauseSim });
	const characterRef = useRef();
	const characterNameRef = useRef("Nina");
	const character = characterDict[characterNameRef.current];
	const characterPerformanceModeAvatarPic = `https://avatarportraits.s3.ca-central-1.amazonaws.com/${characterNameRef.current}Portrait.png`;

	//  const location = useLocation();
	const simViewRef = useRef(null);
	const simTransitionViewRef = useRef(null);
	const userMediaStreamRef = useRef(null);

	const { active, progress, errors, item, loaded, total } = useProgress();
	const saveAudio = useRef(false);
	const saveVideo = useRef(false);

	const [simStarted, setSimStarted] = useState(false);
	const [startLoadingCharacter, setStartLoadingCharacter] = useState(false);
	const [nextTalkingAnim, setNextTalkingAnim] = useState(false);
	const [loadingDone, setLoadingDone] = useState(false);
	const [userSpeaking, setUserSpeaking] = useState(false);
	const [isThinking, setIsThinking] = useState(false);

	// State for status label
	const [statusLabel, setStatusLabel] = useState("");

	const [audioPlaying, setAudioPlaying] = useState(false);

	// State and reference for messages
	const messagesRef = useRef([]);

	// Reference to the transcript and DeepGram status
	const emptyTranscriptCount = useRef(0);
	const areYouThereCheck = useRef(false);
	const areYouThereCheck2 = useRef(false);

	// Reference to VAD (Voice Activity Detection) data
	const vadRefs = useRef({ localUser: { id: "yo" } });

	const userScreenShareStreamRef = useRef(null);

	// Reference to the playing status and start time
	const isAvatarTalkingRef = useRef(true); // avatar talking
	const micManualyMutedRef = useRef(false); // avatar talking

	const startTime = useRef(0);
	const startAfterGptTime = useRef(0);
	const IdleTimeRef = useRef(-1);

	const textRemovedRef = useRef(false);

	// References to the character and character name
	const nextTalkingAnimRef = useRef("Happy");

	// State for camera position and rotation
	// const [camPosition, set_camPosition] = useState(character.cameraPosition);
	const [camRotation, set_camRotation] = useState(character.cameraRotation);
	// Reference to the view
	const view1 = useRef();
	const [simTransition, setSimTransition] = useState(true);

	const [simPaused, setSimPaused] = useState(false);

	const simPausedRef = useRef(false);

	const [videoOn, setVideoOn] = useState(false);
	const [captureOn, setCaptureOn] = useState(false);
	const [hasStoppedCapture, setHasStoppedCapture] = useState(false);
	//  const [characterVideoOn, setCharacterVideoOn] = useState(true);
	const [statusCheck, setStatusCheck] = useState({
		assistant_final_decision: "",
		assistant_said_goodbye: "",
		user_asked_for_questions: "",
		question_list: []
	});

	const closingRef = useRef(false);
	const [simEnded, setSimEnded] = useState(false);
	const [showSocketErrorModal, setShowSocketErrorModal] = useState(false);

	const [showConfirmModal, setShowConfirmModal] = useState(false);
	const [showEndingModal, setShowEndingModal] = useState(false);
	const [showSimEndedModal, setShowSimEndedModal] = useState(false);
	const [currentSession, setCurrentSession] = useState();
	const [softPopupText, setSoftPopupText] = useState("");
	const [generateReport, setGenerateReport] = useState(false);
	const [keepGoing, setKeepGoing] = useState(false);

	const keepGoingRef = useRef(keepGoing);
	useEffect(() => {
		keepGoingRef.current = keepGoing;
	}, [keepGoing]);
	const [endingTimer, setEndingTimer] = useState(15);
	const presentationFinishedRef = useRef(false);
	const [showPresentationPopUp, setShowPresentationPopUp] = useState(false);
	const [presentationFinished, setPresentationFinished] = useState(false);
	const [allowSimControls, setAllowSimControls] = useState(false);
	const [showResumeSimModal, setShowResumeSimModal] = useState(false);
	// useeffect to watch for changes in the allowSimControls state  and to call updateMediaControlsAllowed
	useEffect(() => {
		updateMediaControlsAllowed(allowSimControls);
	}, [allowSimControls]);

	const MAX_IDLE_TIME1 = 6 * 1000; // length of idle time in miliseconds  assistant last timer
	const MAX_IDLE_TIME3 = 10 * 1000; // length of idle time in miliseconds  user last timer

	const MAX_IDLE_TIME2 = 8 * 1000; // length of idle time in miliseconds   are you there time

	const MAX_MIC_TIME1 = 2 * 1000; // length of idle time in miliseconds
	const MAX_MIC_TIME2 = 5 * 1000; // length of idle time in miliseconds

	// Audio context and gain node for volume control
	const audioContext = useRef(null);
	const mixerRef = useRef(null); // Ref for the mixer node

	const { setPollingInterval, clearPollingInterval } = usePollingIntervals();

	const {
		startRecording: startRecordingFull,
		stopRecording: stopRecordingFull,
		pauseRecording,
		resumeRecording,
		stopRecordVideo,
		startRecordVideo,
		addAudioSourceToMixer,
		getElapsedTime,
		getElapsedTimeFromTimestamp,
		getRemainingTime,
		videoStatus,
		progress: uploadProgress
	} = useAudioRecorder({ mixerRef, audioContext, saveVideo, activeSessionDataRef });

	// const { progress: uploadProgress } = useUploadManager();

	const [uploadProgressState, setUploadProgressState] = useState(uploadProgress);

	// const { progress: uploadProgressReal } = useUploadManager();

	const [startUpdateLoop, setStartUpdateLoop] = useState(false);
	const updateLoopRef = useRef(null);
	const lastUpdateTime = useRef(Date.now());
	const activeRequestRef = useRef(null);
	const currentlyPlayingGptIdRef = useRef(null);
	const MicTimeRef = useRef(-1);
	const latencyRef = useRef(-1);

	// const [canSendDeepgramData, setCanSendDeepgramData] = useState(false);
	const mediaFetchRef = useRef(null);

	const canSendDeepgramDataRef = useRef(false);
	const userLanguageRef = useRef(userLanguage);
	useEffect(() => {
		userLanguageRef.current = userLanguage;
	}, [userLanguage]);
	function getTimeStamp() {
		return getElapsedTime() / 1000;
	}
	const {
		// avatarSpeechData,
		getTokenCount,
		createAvatarSpeechData,
		isPreviousRequestSynthesized,
		isResponseStale,
		getAvatarSpeechData,
		getAvatarGptData,
		createAvatarGptData,
		getAvatarGptDataByTranscriptId,
		getUserTranscriptData,
		getLastTranscriptId,
		createUserTranscriptData,
		stopDeepGram,
		startDeepGram,
		handleDeepgramConnection,
		microphoneStarted,
		finishAudioFile,
		startAudioFile,
		getChatHistory,
		getChatHistoryV2,
		getEventHistory,
		saveData,
		loadData,
		isTalkingRef,
		getCurrentlyPlayingAvatarSpeechData,
		getLastNonEmptyTranscript,

		finishGptEvent,
		finishSpeechEvent,
		finishTranscriptEvent
	} = useLiveData({
		userLanguageRef,

		audioContext,
		OnVoiceStart,
		OnVoiceEnd,
		sendMessage2,
		userMediaStreamRef,
		user,
		localUser,
		activeSessionDataRef,
		setUserSpeaking,
		setIsThinking,
		characterNameRef,
		saveAudio,
		setAllowSimControls,
		handleDisconnect
	});

	useEffect(() => {
		const chatHistory = getChatHistory();
		if (chatHistory.length < 1) {
			setAllowSimControls(false);
		}
	}, [getChatHistory]);

	// useEffect(() => {
	// 	console.log("uploadProgressReal: ", uploadProgress);
	// 	setUploadProgressState(uploadProgress);
	// }, [uploadProgress]);

	// Update stopwatch_duration in DB every 10 seconds
	useEffect(() => {
		const updateTime = async () => {
			// console.log("Update time check - paused:", simPausedRef.current);
			if (simPausedRef.current || !activeSessionDataRef.current) {
				console.log("Session Data Not Found");
				return;
			}
			// console.log("Session Data Found");
			try {
				const body = {
					elapsedTime: getElapsedTime(),
					session_id: activeSessionDataRef.current.session_id
				};
				// console.log("Updating elapsed time:", body.elapsedTime);
				await axiosLimitedPost(`${url}/api/sessionData/session/set-stopwatch-duration`, body);
			} catch (error) {
				console.error("Error updating time:", error);
			}
		};

		const interval = setInterval(updateTime, 10000); // 10 seconds

		// Run once immediately instead of waiting for first interval
		updateTime();

		return () => clearInterval(interval);
	}, []);

	const audioEnd = ({ speechId }) => {
		// eslint-disable-next-line no-use-before-define
		const speechData = getAvatarSpeechData(speechId);
		console.log(speechData);
		// Get the old playback time
		// console.log("Check if this is called on pause");
		const endedProperly = speechData.onAudioEnd();
		// if (!endedProperly) {
		// 	return;
		// }
		// eslint-disable-next-line no-use-before-define
		finishSpeechEvent(speechId);
		setMuteMic(false);

		IdleTimeRef.current = getElapsedTime();
		// console.log("timer reset by audioend")

		const nextSpeechId = speechId + 1;

		// const nextData = getAvatarSpeechData(nextSpeechId);
		// if (nextData !== undefined && nextData.gptId === speechData.gptId) {
		// 	emitSynthesizeSpeech(nextData);
		// } else
		// eslint-disable-next-line no-use-before-define
		// {
		isAvatarTalkingRef.current = false;
		micManualyMutedRef.current = false;
		// Set the audio started status
		setAudioPlaying(false);
		MicTimeRef.current = getElapsedTime();

		// Trigger immediate second response if text was removed
		if (textRemovedRef.current) {
			console.log("TEXT REMOVED. GENERATING NEW RESPONSE");
			// eslint-disable-next-line no-use-before-define
			const transcriptId = getLastTranscriptId();
			// eslint-disable-next-line no-use-before-define
			const data = getUserTranscriptData(transcriptId);
			if (data) {
				RequestTextData(data.transcriptId, 2, false);
			}
		} else {
			if (vadRefs.current && vadRefs.current.OnCharacterFinishCb) {
				vadRefs.current.OnCharacterFinishCb();
				vadRefs.current.OnCharacterFinishCb = undefined;
			}
			setUserMicText(pageText.StartSpeaking);
		}
		// }

		if (closingRef.current && !keepGoingRef.current) {
			pauseSim();
			setShowEndingModal(true);
		}

		// eslint-disable-next-line no-use-before-define
		saveData();

		setAllowSimControls(true);

		if (!performanceMode && characterRef.current.State !== "Nod") {
			characterRef.current.StartActiveListening();
			// console.error("Active Listening State");
		}
	};

	// Renaming the functions while destructuring
	const {
		speak: startSpeaking,
		speakV2: startSpeakingV2,
		pause: pauseAudio,
		resume: resumeAudio,
		interrupt: interruptAudio,
		status
	} = useSpeechSynthesizer({
		setShowSocketErrorModal,
		isTalkingRef,
		simPausedRef,
		getTimeStamp,
		addAudioSourceToMixer,
		audioContext,
		onVisemeReceived: handleVisemeReceived,
		onWordReceived: handleWordReceived,
		onSynthesisStarted: handleSynthesisStarted,
		onSynthesisCompleted: handleSynthesisCompleted,
		onSynthesisCanceled: handleSynthesisError,
		onAudioPlaying: onAudioDataPlayed,
		pauseSim,
		onAudioStart: ({ speechId }) => {
			setAllowSimControls(false);
			// eslint-disable-next-line no-use-before-define
			const avatarSpeechData = getAvatarSpeechData(speechId);
			avatarSpeechData.onAudioStart();

			MicTimeRef.current = -1;
			IdleTimeRef.current = getElapsedTime();
			// console.log("timer reset by audioStart")

			if (avatarSpeechData.timeStamp == null) {
				avatarSpeechData.timeStamp = getElapsedTime();
			}
			isAvatarTalkingRef.current = true; // Set the global playing status to true
			setIsThinking(false);
			setAudioPlaying(true); // Update the state of audio playing to true
			setMuteMic(true);

			if (characterRef.current) {
				// If the character reference is set
				// console.log("audio Start", JSON.parse(JSON.stringify(avatarSpeechData.animation)));

				characterRef.current.UpdateAnimations("test QUOTE", avatarSpeechData.animation);
				// Update the animations of the character with the current speech data's animation
				// characterRef.current.StartTalking("Talk_Confident"); // Start the talking animation of the character
				// const randomTalkingAnim = characterDict[characterNameRef.current].animations.TalkingAnimName[randomTalkingAnimIndex];
				// characterRef.current.StartAdditiveAnimation(); //Nodding when start talking
				if (nextTalkingAnimRef.current) {
					characterRef.current.OnGptTalking(nextTalkingAnimRef.current); // Start the talking animation of the character
					// console.error("talking animation set");
					// console.error(nextTalkingAnimRef.current);
				} else {
					characterRef.current.OnGptTalking("Happy");
					// console.error("Did not get talk animation");
				}

				// characterRef.current.StartTalking(randomTalkingAnim)
				// console.error("The Talking Animation is: ", nextTalkingAnimRef.current);
			}

			const prevSpeechId = speechId - 1;
			// eslint-disable-next-line no-use-before-define
			const prevData = getAvatarSpeechData(prevSpeechId);
			if (prevData !== undefined) {
				// console.log("audio Start")
				prevData.clearData();
			}

			const endTime = Date.now();
			const duration = (endTime - startTime.current) / 1000;
			if (duration < 9000) {
				posthog.capture("instage_speakLatency", { duration, setup_id: setup_type, session_id: activeSessionDataRef.current.session_id });
			}
		},
		onAudioEnd: audioEnd
	});

	const { connectionStatus, deepgramStatus, sendMessage, addEventListener, removeEventListener, socket } = useSocket({
		getTimeStamp,
		url: process.env.REACT_APP_BACKEND_LIVE_URL,
		stopDeepGram,
		handleDeepgramConnection,
		instage_id: localUser?.id,
		handleGptResponse,
		handleSpeechStarted,
		handleUtteranceEnd,
		handleTranscript,
		handleConnect,
		handleDisconnect,
		handleGptUserStatementComplete
	});

	useEffect(() => {
		if (!active && loaded === total && total > 0) {
			// console.log(`Loading progress: ${loaded}/${total}`);

			setLoadingDone(true);
		} else if (total > 0) {
			// console.log(`Loading progress: ${loaded}/${total}`);
		}
	}, [active, loaded, total]);

	const fetchVideoAccessAndUpdateAudio = async (caller) => {
		console.log("fetchVideoAccessAndUpdateAudio", caller);
		if (mediaInitialized) {
			const db = await initDB();
			const videoAccess = await db.get("simulation_db", "videoAccess");
			const saveAudioAccess = await db.get("simulation_db", "saveAudioAccess");
			const saveVideoAccess = await db.get("simulation_db", "saveVideoAccess");

			saveAudio.current = saveAudioAccess;
			saveVideo.current = saveVideoAccess;

			console.log(
				`fetchVideoAccessAndUpdateAudio saveAudioAccess ${saveAudioAccess} saveVideoAccess ${saveVideoAccess}  videoAccess ${videoAccess} `
			);

			// Set the state with the fetched value and then fetch the audio
			setVideoOn(videoAccess); // This will trigger a re-render
			setCaptureOn(saveVideoAccess);
			// console.log("captureOn", captureOn);

			await getAudio(videoAccess);
		}
	};

	useEffect(() => {
		try {
			if (mediaInitialized) {
				// console.log("mediaInitialized", mediaInitialized, selectedDevices);
				fetchVideoAccessAndUpdateAudio("media init useeffect");
			}
		} catch (error) {
			console.error(error);
		}
	}, [mediaInitialized, selectedDevices]);

	/**
	 * useEffect hook to fetch session data when simId changes.
	 * If simId is present, it makes an API call to fetch session history.
	 * The response data is then processed and loaded if avatar_speech_data, gpt_data, and transcript_data are present.
	 * Finally, the active session data is updated with the response data.
	 */
	useEffect(() => {
		const fetchData = async () => {
			const params = { session_id: simId };
			// console.log("Get session data");

			const response = await axiosLimitedGet(`${url}/api/sessionData/session`, 1, { params });
			// console.log("session data", response.data);

			if (!response.data.ongoing) {
				setSimEnded(true);
				return;
			}

			// console.log(response.data);
			const { transcript_data } = response.data;
			const { gpt_data } = response.data;
			const { avatar_speech_data } = response.data;

			if (
				avatar_speech_data !== null &&
				avatar_speech_data !== undefined &&
				gpt_data !== null &&
				gpt_data !== undefined &&
				transcript_data !== null &&
				transcript_data !== undefined
			) {
				loadData(avatar_speech_data, gpt_data, transcript_data);
			}
			if (response && response.data) {
				setActiveSessionData(response.data);
				activeSessionDataRef.current = response.data;
				console.log("setStartLoadingCharacter activeSessionDataRef.current", activeSessionDataRef.current);
				if (activeSessionDataRef.current && activeSessionDataRef.current.audience && activeSessionDataRef.current.audience.length > 0) {
					[characterNameRef.current] = activeSessionDataRef.current.audience;
					setStartLoadingCharacter(true);
				}
				if (activeSessionDataRef.current && activeSessionDataRef.current.setup_type) {
					switch (activeSessionDataRef.current.setup_type) {
						case "retail":
							setSimTransitionText(pageText.TheCustomerIsApproaching);
							break;
						case "discovery":
						case "discovery_spin":
						case "closing":
							setSimTransitionText(pageText.TheCustomerIsJoining);
							break;
						case "interview":
							setSimTransitionText(pageText.TheInterviewerIsJoining);
							break;
						case "presentation":
						case "pitch":
						case "business_pitch":
							setSimTransitionText(pageText.YourPartnerIsJoining);
							break;
						case "intro":
						case "freestyle":
						default:
							setSimTransitionText(pageText.TheOtherPersonIsJoining);
							break;
					}
				}
			}
		};
		const load = async () => {
			if (simId) {
				await fetchData();
				console.warn("data fetched");
			} else {
				activeSessionDataRef.current = {};
			}
		};

		if (activeSessionDataRef.current === null) {
			// console.log("need to fetch data");
			load().then((data) => {});
		} else {
			// console.log("data already fetched");
			// console.log(activeSessionDataRef.current);
		}
		if (loadingDone === true) {
			console.warn("loadingDone");

			// onLoadingDone()
		}
	}, [simId, loadingDone, userLanguage]);

	const onLoadingDone = useCallback(
		(userClicked = false) => {
			if (simStarted === false) {
				if (window.performance) {
					const navigationType = performance.getEntriesByType("navigation")[0].type;
					console.log("navigationType", navigationType);
					// bool to check if user is on safari
					const isSafari = /^((?!chrome|android).)*safari/i.test(navigator.userAgent);
					const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent) && !window.MSStream;
					const isFireFox = /firefox/i.test(navigator.userAgent);
					console.log("isSafari", isSafari);
					console.log("isFireFox", isFireFox);
					console.log("isIOS", isIOS);

					if ((navigationType === "reload" || isSafari || isIOS || isFireFox) && userClicked === false) {
						console.log("This page is reloaded", navigationType, userClicked);
						setShowResumeSimModal(true);
					} else {
						// console.log("hasNotRefreshed", hasNotRefreshed, passedState);
						console.log("This page is not reloaded");
						setTimeout(() => {
							if (simTransitionViewRef.current) {
								simTransitionViewRef.current.classList.add(styles.fadeOut);
							}
							if (simViewRef.current) {
								simViewRef.current.classList.add(styles.fadeIn);
							}
							setTimeout(() => {
								setSimTransition(false);
							}, 1500); // Match the duration of the CSS animation
						}, 2000);

						const checkStreamExist = setPollingInterval(() => {
							if (userMediaStreamRef.current) {
								startMeeting();

								clearPollingInterval(checkStreamExist);
							} else if (mediaFetchRef.current == null) {
								try {
									fetchVideoAccessAndUpdateAudio("check if stream exist");
								} catch (error) {
									console.error(error);
								}
								mediaFetchRef.current = 1;
							}
						}, 1000);
					}
				}
			}
		},
		[simStarted]
	);

	const animationRef = useRef();

	useEffect(() => {
		let currentAngle = 0; // Initialize angle outside of the RAF loop

		const animate = () => {
			// Increment the angle and wrap it at 360 degrees
			currentAngle = (currentAngle + 0.5) % 360;

			// Directly update the DOM style if the ref is current
			if (simTransitionViewRef.current) {
				simTransitionViewRef.current.style.background = `
				  linear-gradient(0deg, rgba(255, 255, 255, 0.5), rgba(255, 255, 255, 0.5)),
				  linear-gradient(${currentAngle}deg, rgba(219, 79, 134, 1) -20.75%, rgba(65, 193, 202, 1) 115.91%)`;
			}

			// Request the next animation frame
			animationRef.current = requestAnimationFrame(animate);
		};

		// Start the animation
		animationRef.current = requestAnimationFrame(animate);

		// Cleanup function to cancel the animation frame request when the component unmounts
		return () => cancelAnimationFrame(animationRef.current);
	}, []); // Empty dependency array ensures this effect runs only once after the initial render

	const endSim = useCallback(async () => {
		userMediaStreamRef.current?.getTracks().forEach((track) => track.stop()); // stop the mic and webcam stream
		const transcriptId = getLastTranscriptId();
		finishAudioFile(transcriptId);
		stopSpeakTime();
		await sleep(500);
		setCurrentSession(activeSessionDataRef.current);
		await axiosLimitedPost(`${url}/api/sessionData/session/ongoing`, { session_id: activeSessionDataRef.current.session_id, value: false });
		console.log("sim ended");
		setSimEnded(true);
		// setShowSimEndedModal(true);

		try {
			posthog?.capture("instage_end_sim", { setup_id: setup_type, session_id: activeSessionDataRef.current.session_id });
		} catch (error) {
			console.error("Posthog error:", error);
		}

		if (user && user.id) {
			try {
				// Send email for when they complete a sim if they are a member
				const body = {
					instage_id: user.id,
					role: localUser.roles[0].name
				};

				if (!window.location.hostname.includes("localhost")) {
					await axiosLimitedPost(`${url}/api/users/sendCompletedSimEmailMembers`, body, 1, {}, 30000);
					console.warn("sent email", body.instage_id);
					// await axiosLimitedPost("https://db3c-184-148-29-227.ngrok-free.app/api/users/updateSessionCount", body, 1, {}, 30000);
					await axiosLimitedPost(`${url}/api/users/updateSessionCount`, body, 1, {}, 30000);
					// console.error("sent email", body.instage_id);
				} else {
					// await axiosLimitedPost("https://53c7-45-44-183-85.ngrok-free.app/api/users/sendCompletedSimEmailMembers", body, 1, {}, 30000);
					// await axiosLimitedPost("https://d471-45-44-183-85.ngrok-free.app/api/users/updateSessionCount", body, 1, {}, 30000);
				}
			} catch (error) {
				console.error("Error sending email", error);
			}
		}
	}, []);

	const togglePauseSim = useCallback(
		(skipSpeech = false) => {
			setSimPaused((prevSimPaused) => {
				const newSimPaused = !prevSimPaused;
				IdleTimeRef.current = getElapsedTime();
				console.log("current Time", getElapsedTime() / 1000);
				if (newSimPaused) {
					stopDeepGram();
					pauseSpeakTime();
					if (userMediaStreamRef.current) {
						userMediaStreamRef.current.getTracks().forEach((track) => {
							// eslint-disable-next-line no-param-reassign
							track.enabled = false;
						});
					}
					posthog?.capture("instage_sim_paused", { paused: newSimPaused, timestamp: new Date().toISOString() });
				} else {
					resumeSpeakTime(skipSpeech);
					startDeepGram();
					if (userMediaStreamRef.current) {
						userMediaStreamRef.current.getTracks().forEach((track) => {
							// eslint-disable-next-line no-param-reassign
							track.enabled = true;
						});
					}
					posthog?.capture("instage_sim_resumed", { paused: newSimPaused, timestamp: new Date().toISOString() });
				}
				return newSimPaused;
			});
		},
		[getElapsedTime, startDeepGram, stopDeepGram, pauseSpeakTime, resumeSpeakTime]
	);

	useEffect(() => {
		pauseSimRef.current = pauseSim;
		// Code to be executed when the component mounts
	}, [togglePauseSim]); // The empty array ensures this effect runs once on mount

	useEffect(() => {
		setSidebarContent("AvaView");
		setSidebarContentView("transcript");
		setShowSidebar(window.innerWidth > 1000);
	}, []);

	useEffect(() => {
		presentationFinishedRef.current = presentationFinished;
	}, [presentationFinished]);

	useEffect(() => {
		simPausedRef.current = simPaused;
	}, [simPaused]);

	useEffect(() => {
		if (presentationFinished) {
			// let data = getLastNonEmptyTranscript();

			RequestTextData(0, 0, false);
		}
	}, [presentationFinished]);

	// useEffect(() => {
	// 	// Trigger the modal if the GPT response indicates closing and audio is not playing
	// 	if (closingRef.current && !audioPlaying) {
	// 		togglePauseSim(false);
	// 		setShowEndingModal(true);
	// 	}
	// 	// Additionally, if the audio stops playing and the closing condition was true,
	// 	// it should also trigger the modal
	// }, [audioPlaying, closingRef.current]);

	// When showEndingModal is true, start the countdown timer. When timer reaches 0, end the simulation.
	// When keepGoing is true, reset the timer to 15 seconds and keep going.

	useEffect(() => {
		if (keepGoing) {
			setEndingTimer(15);
			// console.log("keepGoing")
			return () => {};
		}

		if (showEndingModal) {
			const timer = setInterval(() => {
				setEndingTimer((m_endingTimer) => {
					if (m_endingTimer === 1) {
						clearInterval(timer);
						//  console.log("auto end sim");
						endSim("auto");
						setShowEndingModal(false);
					}
					return m_endingTimer - 1;
				});
			}, 1000);
			return () => clearInterval(timer);
		}
		return () => {};
	}, [showEndingModal, keepGoing, endSim]);

	useEffect(() => {
		if (simStarted && (connectionStatus === connectionStatusOptions.Disconnected || deepgramStatus === connectionStatusOptions.Error)) {
			// if (!simPaused) {
			// 	togglePauseSim(false);
			// }
			// console.log(`simStarted ${simStarted} connectionStatus ${connectionStatus} deepgramStatus ${deepgramStatus} simPaused ${simPaused} `)
			setShowSocketErrorModal(true);
		}
	}, [togglePauseSim, simStarted, connectionStatus, deepgramStatus, simPaused]);

	useEffect(
		() => () => {
			try {
				userMediaStreamRef.current?.getTracks().forEach((track) => track.stop()); // stop the mic and webcam stream
			} catch (e) {
				console.error(e);
			}

			// cleanup code here
		},
		[]
	);

	const Update = useCallback((deltaTime) => {
		const timeStamp = getElapsedTime();
		let timerDuration = timeStamp - IdleTimeRef.current;
		// console.log(`Update loop running every second. Delta time: ${deltaTime}ms   Time:${timeStamp/1000}  timerDuration ${timerDuration}`);
		if (
			IdleTimeRef.current !== -1 &&
			!areYouThereCheck2.current &&
			(activeSessionDataRef.current.setup_type !== "presentation" ||
				activeSessionDataRef.current.setup_type !== "business_pitch" ||
				presentationFinishedRef.current)
		) {
			if (timerDuration > MAX_IDLE_TIME1 && areYouThereCheck.current === false) {
				//! areYouThereCheck.current

				const chat_history = getChatHistory();
				const last_item = chat_history[chat_history.length - 1];
				if (last_item && last_item.role === "assistant") {
					console.log("idle timer 1 ran", timeStamp / 1000, areYouThereCheck.current);

					const transcriptId = getLastTranscriptId();
					// Get the data associated with the transcript ID
					const data = getUserTranscriptData(transcriptId);
					if (data) {
						// forcibly turn off custom vad when requesting idle data
						isTalkingRef.current = false;
						RequestTextData(data.transcriptId, 2, true);
						finishTranscriptEvent(data.transcriptId);

						areYouThereCheck.current = true;
						IdleTimeRef.current = getElapsedTime();
						timerDuration = timeStamp - IdleTimeRef.current;
						// console.log("timer reset by idleTimer1")
					}
				}
			}

			if (timerDuration > MAX_IDLE_TIME3 && areYouThereCheck.current === false) {
				//! areYouThereCheck.current

				const chat_history = getChatHistory();
				const last_item = chat_history[chat_history.length - 1];
				if (last_item && last_item.role === "user") {
					console.log("idle timer 3 ran", timeStamp / 1000, areYouThereCheck.current);

					const transcriptId = getLastTranscriptId();
					// Get the data associated with the transcript ID
					const data = getUserTranscriptData(transcriptId);
					if (data) {
						// forcibly turn off custom vad when requesting idle data
						isTalkingRef.current = false;

						RequestTextData(data.transcriptId, 2, true);
						areYouThereCheck.current = true;
						IdleTimeRef.current = getElapsedTime();
						timerDuration = timeStamp - IdleTimeRef.current;
						// console.log("timer reset by idleTimer3")
					}
				}
			}

			if (timerDuration > MAX_IDLE_TIME2 && areYouThereCheck.current) {
				console.log("idle timer 2 ran", timeStamp / 1000, areYouThereCheck.current);

				IdleTimeRef.current = getElapsedTime();
				timerDuration = timeStamp - IdleTimeRef.current;
				// console.log("timer reset by idleTimer2")
				// console.log("Are you there: ", pageText);

				const speech_data = createAvatarSpeechData(-2, pageText.AreYouThere);
				emitSynthesizeSpeech(speech_data);
				areYouThereCheck.current = false;
				areYouThereCheck2.current = true;
			}
		}
		if (MicTimeRef.current !== -1) {
			const micTimerDuration = timeStamp - MicTimeRef.current;
			if (micTimerDuration > MAX_MIC_TIME2) {
				// console.log("second timer ", "canSendDeepgramDataRef",canSendDeepgramDataRef.current)
				if (canSendDeepgramDataRef.current === true) {
					setUserMicText(pageText.FinishSpeaking);
				}
			} else if (micTimerDuration > MAX_MIC_TIME1) {
				setUserMicText(pageText.Listening);
			}
		}
	}, []);

	useEffect(() => {
		// Clear the interval at the start
		if (updateLoopRef.current) {
			clearInterval(updateLoopRef.current);
			updateLoopRef.current = null;
		}

		if (startUpdateLoop) {
			updateLoopRef.current = setInterval(() => {
				const currentTime = Date.now();
				const deltaTime = currentTime - lastUpdateTime.current;
				lastUpdateTime.current = currentTime;
				Update(deltaTime);
			}, 1000);
		}

		// Clear the interval when the component unmounts
		return () => {
			if (updateLoopRef.current) {
				clearInterval(updateLoopRef.current);
				updateLoopRef.current = null;
			}
		};
	}, [startUpdateLoop, Update]);

	function sendMessage2(event, msg) {
		sendMessage(event, msg);
	}
	async function initializeAudioContext() {
		if (audioContext.current == null) {
			console.log("create audioContext");
			audioContext.current = new (window.AudioContext || window.webkitAudioContext)();
		}

		if (mixerRef.current == null) {
			console.log("create mixer");
			mixerRef.current = audioContext.current.createGain();
		}
	}
	const healthCheckRef = useRef(null);
	async function startMeeting() {
		initializeAudioContext();
		console.log("startMeeting");
		setSimStarted(true);
		setSidebarContent("AvaView");
		setSidebarContentView("transcript");
		if (window.innerWidth > 1000) {
			setShowSidebar(true);
		}
		await sleep(10);
		// console.log(activeSessionDataRef.current.chat_history)
		const chat_history = getChatHistory();
		console.log(chat_history);
		if (activeSessionDataRef.current && chat_history.length > 0) {
			activeSessionDataRef.current.chat_history = chat_history;
		}
		if (activeSessionDataRef.current && activeSessionDataRef.current.chat_history.length > 0) {
			const lastChatObject = activeSessionDataRef.current.chat_history[activeSessionDataRef.current.chat_history.length - 1];
			const lastSpeechData = {};
			//    console.log(activeSessionDataRef.current.avatar_speech_data)
			const lastChatMessage = lastChatObject.content.split(":").slice(1).join(" ");
			if (lastChatObject.role === "assistant") {
				if (!performanceMode) {
					characterRef.current.StartGreetingV2(() => {
						startSpeakTime();

						const oldSpeechId = lastChatObject.speechId;
						const data = getAvatarSpeechData(oldSpeechId);

						if (data) {
							emitSynthesizeSpeech(data, true);
						} else {
							RequestSpeechData(lastChatMessage, -1, true);
						}

						vadRefs.current.OnCharacterFinishCb = async () => {
							startDeepGram();
						};

						//
					});
				} else {
					startSpeakTime();
					const oldSpeechId = lastChatObject.speechId;
					const data = getAvatarSpeechData(oldSpeechId);

					if (data) {
						emitSynthesizeSpeech(data, true);
					} else {
						RequestSpeechData(lastChatMessage, -1, true);
					}

					vadRefs.current.OnCharacterFinishCb = async () => {
						startDeepGram();
					};
				}
			} else {
				startSpeakTime();
				isAvatarTalkingRef.current = false;
				micManualyMutedRef.current = false;
				startDeepGram();
			}

			updateMessages("", "force Update");
		}
		healthCheckRef.current = setPollingInterval(async () => {
			const data = await axiosLimitedGet(liveUrl, 1);
			//	console.log(data);
		}, 1000);
	}

	useEffect(() => {
		console.log("healthCheckRef", healthCheckRef.current);
		return () => {
			clearPollingInterval(healthCheckRef.current);
		};
	}, []);
	const ManualMuteMic = useCallback(() => {
		const timeStamp = getElapsedTime();
		console.log("manual mute", timeStamp / 1000);

		const transcriptId = getLastTranscriptId();

		// Get the data associated with the transcript ID
		const data = getUserTranscriptData(transcriptId);

		if (data && validateString(data.transcript)) {
			areYouThereCheck.current = false;
			IdleTimeRef.current = getElapsedTime();

			// isAvatarTalkingRef.current = true; // Set the global playing status to true
			micManualyMutedRef.current = true;
			setAudioPlaying(true); // Update the state of audio playing to true
			setMuteMic(true);
			MicTimeRef.current = -1;

			const gptData = getAvatarGptDataByTranscriptId(data.transcriptId);
			console.log("ManualMuteMic gpt", gptData);
			if (!gptData) {
				RequestTextData(data.transcriptId);
			}
		}
	}, []);

	/**
	 * Asynchronously gets the audio from the user's microphone.
	 * First, it checks if the user has granted microphone access.
	 * If access is granted, it gets the media from the user's microphone.
	 *
	 * @async
	 */
	async function getAudio(askForVideo) {
		console.log("getAUDIO");
		// Check if the user has granted microphone access
		const { micAccess, videoAccess } = await CheckPermissions(askForVideo);
		if (micAccess) {
			// Get the media from the user's microphone
			await GetMedia(userMediaStreamRef, videoAccess, setVideoOn);
		}
	}
	// useRef to count calls
	const callCount = useRef({});
	const getCustomerExpression = async (text, transcriptId, delay = false) => {
		// console.log("/GetCustomerExpressions");

		// check if the text has a , or . if not return
		if (text.indexOf(".") === -1 && text.indexOf(",") === -1) {
			// return;
		}
		const setupData = activeSessionDataRef.current;
		const data2 = {
			instage_id: setupData.instage_id,
			company: setupData.company,
			setup_type: setupData.setup_type,
			interimText: text,
			audience: [characterNameRef.current],
			personality: setupData.personality
		};

		if (callCount.current[transcriptId] === undefined) {
			callCount.current[transcriptId] = 0;
		}
		callCount.current[transcriptId] += 1;
		if (callCount.current[transcriptId] > 2) {
			console.log("getCustomerExpression cancelled by limit", callCount.current[transcriptId]);
			return;
		}
		axiosLimitedPost(`${url}/api/animation/GetCustomerExpressions`, data2, 1).then((response) => {
			// nextTalkingAnimRef.current = null;
			// console.log("GetCustomerExpressions", response.data);
			try {
				// const delimiterIndex = response.data.indexOf("\n");
				// const jsonString = response.data.slice(0, delimiterIndex).trim();
				// const jsonObject = JSON.parse(jsonString);
				// console.log(jsonObject);
				if (!delay) {
					if (characterRef.current) {
						characterRef.current.OnGptReaction(response.data.animation);
					}
				} else {
					vadRefs.current.OnCharacterFinishCb = async () => {
						// await sleep(1000);
						if (characterRef.current) {
							characterRef.current.OnGptReaction(response.data.animation);
							//	posthog.capture("instage_animation_gpt_reaction", { animation: response.data.animation });
						}
						vadRefs.current.OnCharacterFinishCb = undefined;
					};
				}

				nextTalkingAnimRef.current = response.data.talkingAnimation;
				// posthog.capture("instage_animation_talking_animation", { talkingAnimation: response.data.talkingAnimation });

				// console.error(response.data.talkingAnimation);
			} catch (error) {
				console.error(error);
				nextTalkingAnimRef.current = "Happy";
			}
		});
	};

	function OnVoiceStart() {
		// characterRef.current.StartAdditiveAnimation("NodAdditive") // starts when user starts
		// interruptAllSpeeches()
	}

	function OnVoiceEnd() {
		if (!performanceMode) {
			characterRef.current.StartAdditiveAnimation("NodAdditive"); // starts when user stops
			console.log("Nodding");
		}

		// try {
		//     if (!characterRef.current.animation.includes("Nod")) {
		//         characterRef.current.StartAdditiveAnimation("NodAdditive");
		//     }
		// } catch (err) {
		//     console.error(err);
		// }
	}

	function handleSpeechStarted(data) {
		// console.error("handleSpeechStarted", data);
	}

	function handleUtteranceEnd(data) {
		// console.error("handleUtteranceEnd", data);
	}

	/**
	 * handleTranscript is a function that is triggered when a transcript is received.
	 * It updates the messages and text states.
	 * If the incoming transcript contains more than 2 words, it identifies the currently playing speech data and interrupts it.
	 * If it receives 3-4 empty transcripts consecutively while the avatar is not talking
	 *  and the transcript.current is not empty, it invokes RequestTextData.
	 * @param {object} param0 - The object containing transcript details.
	 * @param {string} param0.transcript - The transcript text.
	 * @param {number} param0.duration - The duration of the transcript.
	 * @param {boolean} param0.is_final - Flag to check if the transcript is final.
	 * @param {boolean} param0.speech_final - Flag to check if the speech is final.
	 */

	function handleTranscript({ transcript, duration, is_final, speech_final }) {
		if (isAvatarTalkingRef.current || micManualyMutedRef.current) {
			return;
		}
		// Get the last transcript ID
		let transcriptId = getLastTranscriptId();

		// Get the data associated with the transcript ID
		let data = getUserTranscriptData(transcriptId);

		// console.log(transcript, is_final, speech_final)
		// If the data is undefined or (the speech is final and the transcript is not empty)
		if (data === undefined || data === null || (data.speechFinal === true && data.isFinal === true && validateString(data.transcript))) {
			// Log the reason for new data creation
			// if (data === undefined || data === null) {
			// 	console.log("Creating new UserTranscriptData because data is undefined");
			// } else {
			// 	console.log(
			// 		`Creating new UserTranscriptData because speech is${data.speechFinal === true ? "final" : "not final"} and transcript is ${
			// 			data.transcript !== "" ? "not empty" : "empty"
			// 		}`
			// 	);
			// }
			// Create a new UserTranscriptData object
			data = createUserTranscriptData(); // these start with both bools as false

			transcriptId = data.transcriptId;
			canSendDeepgramDataRef.current = false;
			// console.log("create Transcript object")
		}

		if (data.audioFileStarted === false && validateString(transcript)) {
			startAudioFile(transcriptId);
			data.audioFileStarted = true;
			//  console.log("first case", JSON.stringify(data))
		} else if (data.audioFileStarted === false && !validateString(transcript)) {
			// console.log("second case",transcript, JSON.stringify(data))
		}

		data.updateTranscript(transcript, is_final, speech_final, duration);

		if (data.startTime != null && data.timeStamp == null) {
			data.timeStamp = getElapsedTime();
			canSendDeepgramDataRef.current = true;
		}

		if (validateString(transcript)) {
			setIsThinking(false);
		}

		// If the transcript is final
		if (is_final && validateString(transcript)) {
			console.log(`final transcript -${transcript}-`, getElapsedTime() / 1000);

			// Update the messages
			IdleTimeRef.current = getElapsedTime();
			//  console.log("timer reset by onTranscript1")

			areYouThereCheck.current = false;
			areYouThereCheck2.current = false;
			updateMessages("user", transcript);
			getCustomerExpression(transcript, transcriptId, 0);
		}
		if (data.speechFinal && validateString(data.transcript)) {
			finishAudioFile(transcriptId);
		}

		if (transcript === "") {
			// Start by checking if the avatar is not talking, which is a common condition in multiple branches
			if (!isAvatarTalkingRef.current) {
				// Increment the empty transcript count for all cases where the avatar is not talking
				emptyTranscriptCount.current += 1;

				// Check if the transcript is not empty and the speech is not final
				if (data.transcript !== "" && data.speechFinal === false) {
					// If there are more than 2 empty transcripts
					if (emptyTranscriptCount.current >= 2) {
						// console.log("SPECIAL FINISH TRANSCRIPT");
						// Set the speech and transcript as final
						finishTranscriptEvent(data.transcriptId);
						finishAudioFile(transcriptId);
						// Reset the empty transcript count
						emptyTranscriptCount.current = 0;
					}
				} else if (data.transcript === "") {
					// If the transcript is empty
					// If this is a presentation and the presentation is not finished, trigger the presentation pop up
					if (
						activeSessionDataRef.current &&
						(activeSessionDataRef.current.setup_type === "presentation" ||
							activeSessionDataRef.current.setup_type === "business_pitch") &&
						!presentationFinishedRef.current
					) {
						// Trigger first idle warning if there are more than 10 empty transcripts
						if (emptyTranscriptCount.current === 10) {
							setShowPresentationPopUp(true);
						}
						// If this is not a presentation OR the presentation is finished, trigger the idle warnings
					} else if (emptyTranscriptCount.current === 40) {
						setSoftPopupText(pageText.WeArentDetectingMicrophone);
					}
				}
				// console.log("TRANSCRIPT COUNT: ", emptyTranscriptCount.current);
			} else if (isAvatarTalkingRef.current) {
				// If the avatar is talking, reset the empty transcript count
				emptyTranscriptCount.current = 0;
			}
		} else {
			IdleTimeRef.current = getElapsedTime();
			// console.log("timer reset by onTranscript2")

			areYouThereCheck.current = false;
			areYouThereCheck2.current = false;
			if (validateString(data.transcript)) {
				// Interrupt all speeches
				// interruptAllSpeeches()
			}

			// Reset the empty transcript count
			emptyTranscriptCount.current = 0;
			// areYouThereCheck.current = false;
		}

		// If the speech is final and the transcript is not empty
		if (data.speechFinal && validateString(data.transcript)) {
			setIsThinking(true);
			finishTranscriptEvent(data.transcriptId);
			saveData();

			// console.log("presentationFinished: ", presentationFinishedRef.current);
			if (
				(activeSessionDataRef.current &&
					activeSessionDataRef.current.setup_type !== "presentation" &&
					activeSessionDataRef.current.setup_type !== "business_pitch") ||
				presentationFinishedRef.current
			) {
				// Request text data
				RequestTextData(data.transcriptId);
			}
		}
	}

	async function* receiveMultipleResponses(
		eventToSend,
		eventToReceive,
		completionEvent,
		message,
		attempt,
		input_activeRequestRef,
		timeoutDuration = 2500
	) {
		const messageQueue = [];
		let resolvePromise;
		let rejectPromise;
		let isCompleted = false; // Tracks if the completion event has been received
		let timeout; // Holds the current timeout ID
		let lastResetTime; // Variable to store the last reset time of the timeout

		const { gptId } = message;

		const nextMessagePromise = () =>
			new Promise((resolve, reject) => {
				resolvePromise = resolve;
				rejectPromise = reject;
			});

		// Function to reset the timeout
		const resetTimeout = () => {
			clearTimeout(timeout);
			// console.log(`Resetting timeout at ${new Date().toISOString()}`);
			lastResetTime = Date.now(); // Update last reset time

			timeout = setTimeout(() => {
				removeEventListener(eventToReceive, messageHandler);
				removeEventListener(completionEvent, completionHandler);
				const duration = Date.now() - lastResetTime;

				console.warn(`Timeout occurred at ${new Date().toISOString()} after ${duration} ms  gptId ${gptId} attempt ${attempt}`);

				//  console.log("timeout duration",timeoutDuration)
				rejectPromise(new Error("GPT Response timeout"));
			}, timeoutDuration);
		};

		function messageHandler(response) {
			const { attempt: remote_attempt, gptId: remote_gptId } = response;
			const activeRequest = activeRequestRef.current;
			const responseCheck = gptId === remote_gptId && (activeRequest === null || activeRequest === remote_attempt);
			// console.log(`gptId ${gptId} remote_gptId ${remote_gptId} result ${responseCheck}`)
			// Check if this is the first message from any request or from the active request
			if (responseCheck) {
				activeRequestRef.current = remote_attempt; // Set or keep this request as active
				const currentTime = Date.now();
				const durationSinceLastReset = currentTime - lastResetTime;

				messageQueue.push(response);
				resetTimeout();
				resolvePromise();
			} else {
				console.log(
					`Message Ignored gptId ${gptId} remote_gptId ${remote_gptId} attempt ${activeRequest} remote_attempt ${remote_attempt} after ms:`,
					JSON.stringify(response)
				);
			}
		}

		function completionHandler(response) {
			const { attempt: remote_attempt, gptId: remote_gptId, functionOutput, promptLayer_request_id, metadata } = response;
			//  console.log("completionHandler",response.functionOutput,functionOutput)
			let activeRequest = activeRequestRef.current;
			const responseCheck = gptId === remote_gptId && (activeRequest === null || activeRequest === remote_attempt);

			if (responseCheck) {
				// const currentTime = Date.now();
				// const durationSinceLastReset = currentTime - lastResetTime;

				handleGptResponseFull(functionOutput, remote_gptId, promptLayer_request_id, metadata);
				activeRequest = remote_attempt;
				isCompleted = true;
				clearTimeout(timeout);
				resolvePromise();
			} else {
				// const currentTime = Date.now();
				// const durationSinceLastReset = currentTime - lastResetTime;
				handleGptResponseFull(functionOutput, remote_gptId, promptLayer_request_id, metadata);
			}
		}

		addEventListener(eventToReceive, messageHandler);
		addEventListener(completionEvent, completionHandler);
		sendMessage(eventToSend, message);

		// Initialize the timeout
		resetTimeout();

		try {
			while (!isCompleted) {
				if (messageQueue.length === 0) {
					// console.log("Waiting for next message or completion...");
					// eslint-disable-next-line no-await-in-loop
					await nextMessagePromise().catch((error) => {
						throw error;
					});
				}

				if (messageQueue.length > 0) {
					yield messageQueue.shift();
				}
			}
		} catch (error) {
			console.error("Error in generator function:", error);

			yield error;
		} finally {
			clearTimeout(timeout);
			removeEventListener(eventToReceive, messageHandler);
			removeEventListener(completionEvent, completionHandler);
		}
	}

	/**
	 * RequestTextData is a function that is usually called to generate a GPT response.
	 * It creates a new AvatarGptData object and emits a 'generate-gpt-response' event to the socket.
	 * After emitting the event, it stops the DeepGram.
	 */
	async function RequestTextData(transcriptId, maxRetries = 2, isIdleCheck = false) {
		const timeStamp = getElapsedTime();
		startTime.current = Date.now();

		// const eventHistory = getEventHistory();
		// console.log("Token Count: ", getTokenCount());
		if (getTokenCount() > 400000) {
			console.log("RequestTextData stopped after TokenLimit");
			endSim("auto");
			posthog?.capture("instage_auto_end_token_limit", { setup_id: setup_type, instage_id: localUser.id });
			return;
		}
		if (timeStamp > 40 * +60 * 1000) {
			// 20 minutes in milliseconds
			console.log("RequestTextData stopped after 20 minutes");

			endSim("auto");
			posthog?.capture("instage_auto_end_duration_limit", { setup_id: setup_type, instage_id: localUser.id });

			return;
		}

		console.log(`request Text data transcriptId ${transcriptId}  `, timeStamp / 1000);
		// if (isTalkingRef.current == true) {
		//     console.log("RequestTextData stop by istalking")
		//     return;
		// }

		// let gptId = Math.floor(Math.random() * 1000);
		const chat_history = getChatHistoryV2();
		// get last message
		const last_message = chat_history[chat_history.length - 1];
		// last_message word count
		const last_message_word_count = last_message.content.split(" ").length;
		// get second last message
		const second_last_message = chat_history[chat_history.length - 2];

		if (
			last_message &&
			last_message.role === "user" &&
			second_last_message &&
			second_last_message.role === "user" &&
			last_message_word_count < 3
		) {
			console.log(`request Text data transcriptId ${transcriptId} Canceled less than 3 words `, timeStamp / 1000);

			return;
		}
		const previousMessageIsUser = second_last_message && second_last_message.role === "user";

		const data = createAvatarGptData(transcriptId, isIdleCheck);

		if (data.startTime != null && data.timeStamp == null) {
			data.timeStamp = getElapsedTimeFromTimestamp(data.startTime);
		}
		// Record the start time

		const payload = {
			gptId: data.gptId,
			chat_history,
			forceNextQuestion: textRemovedRef.current,

			// Data that doesn't change:
			setup_data: { userLanguage, ...activeSessionDataRef.current },
			status_check: statusCheck
		};
		activeRequestRef.current = null;
		for (let attempt = 0; attempt < maxRetries; attempt++) {
			const timeoutDuration = 2000;
			payload.attempt = attempt;
			if (attempt > 0 && previousMessageIsUser === true) {
				const gptData = getAvatarGptDataByTranscriptId(transcriptId - 1);
				if (gptData && gptData.functionOutput && gptData.functionOutput.dialogue && validateString(gptData.functionOutput.dialogue)) {
					console.warn("trying to use old response");
					handleGptResponse({
						attempt,
						text: gptData.functionOutput.dialogue,
						gptId: data.gptId,
						user_statement_complete: gptData.functionOutput.user_statement_complete
					});
					break;
				}
			}

			const messageGenerator = receiveMultipleResponses(
				"generate-gpt-response",
				"gpt-response",
				"gpt-response-full",
				payload,
				attempt,
				activeRequestRef,
				timeoutDuration * (attempt + 1)
			);
			try {
				console.log(`Attempting retry ${attempt + 1}/${maxRetries}  for gptId ${payload.gptId} ${getTimeStamp()}`);
				let isSuccess = true;
				// eslint-disable-next-line
				for await (const message of messageGenerator) {
					if (message instanceof Error) {
						isSuccess = false;
						// Handle error here
						console.error("Error received:", message);
						if (message.message === "GPT Response timeout") {
							// Retry if it's a timeout error
							//   console.log(`Attempting retry ${attempt + 1}/${maxRetries}`);

							break; // Exit the current generator loop to retry
						} else {
							// Handle other errors differently
							throw message; // Rethrow error to be caught by outer catch block
						}
					}
					message.attempt = attempt + 1;
					handleGptResponse(message);
				}

				data.attempt = attempt + 1;
				// console.log("generation Status "+isSuccess)
				if (isSuccess) {
					// Successful completion of for-await loop, no need to retry
					break;
				}
				if (attempt === maxRetries - 1) {
					try {
						posthog?.capture("instage_error_gpt_timeout", { setup_id: setup_type, instage_id: localUser.id });
					} catch (err) {
						console.log(err);
					}
					throw Error(`failed gpt after ${attempt + 1} attempts`);
				}
			} catch (error) {
				// Handle any unexpected errors
				console.error("Unexpected error:", error);
				break;
			}
		}
	}

	/**
	 * handleGptResponse is a function that is called when a GPT response is received.
	 * It logs the response and calls the RequestSpeechData function.
	 * @param {string} text - The text of the response.
	 * @param {number} gptId - The ID of the GPT.
	 * @param {boolean} user_statement_complete - Flag to check if the user statement is complete.
	 */
	async function handleGptResponse({ attempt, text, gptId, user_statement_complete, textRemoved }) {
		const timeStamp = getElapsedTime();

		// console.log("gpt-response", text, gptId, attempt, timeStamp / 1000);

		textRemovedRef.current = textRemoved;

		if (true) {
			// isTalkingRef.current == false
			if (user_statement_complete === false) {
				await sleep(1000);
			}

			RequestSpeechData(text, gptId);
		} else {
			console.log("handleGptResponse stop by istalking");
		}
	}

	function handleGptUserStatementComplete(gptId, user_statement_complete) {
		// console.log('gpt-user-statement-complete', gptId, user_statement_complete);
	}

	/**
	 * RequestSpeechData is a function that is usually called from the websocket after receiving text from gpt.
	 * It creates a new AvatarSpeechData object and checks if the previous request has been synthesized.
	 * @param {string} text - The text to be synthesized.
	 * @param {string} [gptId=-1] - The ID of the GPT. Optional, defaults to -1.
	 */
	function RequestSpeechData(text, gptId = -1, dirtyOverride = false) {
		const timeStamp = getElapsedTime();

		const cleanText = cleanResponse(text);
		// console.log("RequestSpeechData ", gptId, cleanText, timeStamp / 1000);
		if (isTalkingRef.current === true) {
			console.error("speech not started user is talking");
			return;
		}

		/**
		 * @type {AvatarSpeechData}
		 */
		const data = createAvatarSpeechData(gptId, cleanText);
		// Check if the previous request has been synthesized
		if (!isPreviousRequestSynthesized(data.speechId)) {
			console.error("previous response not synthesized");

			return;
		}
		if (isResponseStale(data.speechId)) {
			console.error("response is stale");
			return;
		}

		startAfterGptTime.current = Date.now();

		emitSynthesizeSpeech(data, dirtyOverride);

		// Call the function to emit 'SynthesizeSpeech' event to the socket

		// console.log("RequestSpeechData speechId:", data.speechId, "gptId:", gptId)
	}

	/**
	 * emitSynthesizeSpeech is a function that emits a 'SynthesizeSpeech' event to the socket.
	 * @param {AvatarSpeechData} data - The AvatarSpeechData object.
	 */
	function emitSynthesizeSpeech(data, dirtyOverride = false) {
		const timeStamp = getElapsedTime();
		if (simPausedRef.current === false) {
			if (isAvatarTalkingRef.current === false || dirtyOverride === true) {
				const isFireFox = /firefox/i.test(navigator.userAgent);
				const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
				if (userLanguage === "en") {
					if (isFireFox || isIOS) {
						startSpeakingV2(data.speechId, data.text, characterNameRef.current); // characterNameRef.current
					} else {
						startSpeaking(data.speechId, data.text, characterNameRef.current); // characterNameRef.current
					}
				} else if (userLanguage === "fr") {
					if (isFireFox || isIOS) {
						startSpeakingV2(data.speechId, data.text, `${characterNameRef.current}_fr`); // characterNameRef.current
					} else {
						startSpeaking(data.speechId, data.text, `${characterNameRef.current}_fr`); // characterNameRef.current
					}
				}
			} else {
				// console.log("Avatar is currently talking");
			}
		} else {
			console.log("Simulation is currently paused");
		}

		// sendMessage('SynthesizeSpeech', { speechId: data.speechId, voice: characterNameRef.current, text: data.text });
	}

	/**
	 * handleSynthesisStarted is a function that is called when the synthesis starts.
	 * @param {number} speechId - The ID of the speech.
	 */
	function handleSynthesisStarted({ speechId }) {
		const currentSpeechData = getAvatarSpeechData(speechId);
		currentSpeechData.startGeneration();
	}

	/**
	 * handleSynthesisCompleted is a function that is called when the synthesis is completed.
	 * It sets the generationComplete property of the AvatarSpeechData object to true.
	 * @param {number} speechId - The ID of the speech.
	 */
	function handleSynthesisCompleted({ speechId }) {
		const currentSpeechData = getAvatarSpeechData(speechId);
		currentSpeechData.finishGeneration();
	}

	/**
	 * handleVisemes is a function that is called when a viseme is received.
	 * It pushes the viseme to the visemeQueue of the AvatarSpeechData object and creates a keyFrame for the animation.
	 * @param {number} speechId - The ID of the speech.
	 * @param {object} visime - The viseme object.
	 */
	function handleVisemeReceived({ speechId, visime }) {
		const avatarSpeechData = getAvatarSpeechData(speechId);
		avatarSpeechData.visemeQueue.push(visime);
		const keyFrame = { time: visime.audioOffset / 1000 / 10000, value: soundToVisemeMapping[visemeObject[visime.visemeId].sounds[0]] };
		avatarSpeechData.animation.push(keyFrame);
	}

	/**
	 * handleWords is a function that is called when a word is received.
	 * It pushes the word to the wordQueue of the AvatarSpeechData object.
	 * @param {number} speechId - The ID of the speech.
	 * @param {object} word - The word object.
	 */
	function handleWordReceived({ speechId, word }) {
		const avatarSpeechData = getAvatarSpeechData(speechId);

		avatarSpeechData.wordQueue.push(word);
	}

	/**
	 * This function is called when an audio data has been played.
	 * It updates the playback time and the messages based on the duration of the audio data.
	 *
	 * @param {string} speechId - The ID of the speech.
	 * @param {number} duration - The duration of the audio data.
	 */
	function onAudioDataPlayed({ speechId, duration }) {
		IdleTimeRef.current = getElapsedTime();
		// console.log("timer reset by audioPlaying")

		const speechData = getAvatarSpeechData(speechId);
		// Get the old playback time
		// Calculate the new playback time
		const newDuration = duration;
		let text = "";

		// Iterate over the word queue
		speechData.wordQueue.forEach((word, index) => {
			// console.log(word);
			// Calculate the word offset
			const wordOffset = word.audioOffset / 1000 / 10000;
			//  console.log(word.text,wordOffset , newDuration)
			// Check if the word offset is within the old and new duration
			if (wordOffset < newDuration) {
				if (word.boundaryType === "PunctuationBoundary") {
					text = `${text.trimEnd()}${word.text} `;
				} else {
					// Add the word to the text
					text += `${word.text} `;
				}
			}
		});

		const newText = text.slice(speechData.spokenText.length);

		if (text !== "") {
			speechData.spokenText = text;
		}
		// console.log("onAudioDataPlayed",speechId, newDuration,text,"||||", newText)

		// Update the messages
		if (newText !== "") {
			updateMessages("assistant", newText);
		}

		// Update the playback time
		speechData.playbackTime = getElapsedTime() - speechData.timeStamp;
		if (newDuration > speechData.wordQueue[speechData.wordQueue.length - 1].audioOffset / 1000 / 10000) {
			// console.log("audio end", speechId);
			//	console.log("audio end", speechData);
			// audioEnd({ speechId });
		}
		// if (speechData.generationComplete && speechData.audioQueue.length == 0) {
		//     speechData.clearData();
		//     if (speechData.source) {
		//         speechData.source.stop();
		//         speechData.source = null;
		//     }
		// }
	}

	/**
	 * This function interrupts the audio for a given speech ID.
	 * It removes the onended event listener to prevent it from firing and stops the audio source.
	 *
	 * @param {string} speechId - The ID of the speech to interrupt.
	 */
	function interruptSpeech(speechId) {
		// console.log("interruptSpeech");
		const data = getAvatarSpeechData(speechId);

		data.clearData();
		isAvatarTalkingRef.current = false;
	}

	/**
	 * This function interrupts all ongoing speeches.
	 */
	// function interruptAllSpeeches() {
	//     console.log("interruptAllSpeeches");
	//     interruptAudio();
	//     characterRef.current.StopSpeech();

	//     const speechIds = Object.keys(avatarSpeechData);
	//     for (let i = 0; i < speechIds.length; i++) {
	//         interruptSpeech(speechIds[i]);
	//     }
	// }

	/**
	 * Updates the messages state with new content.
	 * If the role of the new content is the same as the role of the last message, the content is appended.
	 * Otherwise, a new message with the new role and content is added.
	 *
	 * @param {*} role - The role of the message (e.g., 'user', 'bot').
	 * @param {*} content - The content of the message.
	 */
	function updateMessages(role, content) {
		// console.log("updateMessages", role, content)
		// Return early if content is empty
		if (!content) {
			return;
		}
		const chat_history = getChatHistory();
		activeSessionDataRef.current.chat_history = chat_history;

		updateChatMessages(chat_history);
	}

	/**
	 * handleSynthesisError is a function that is called when there is an error in the synthesis.
	 * It sets the generationComplete property of the AvatarSpeechData object to false.
	 * @param {number} speechId - The ID of the speech.
	 */
	function handleSynthesisError({ speechId }) {
		console.error("handleSynthesisError", speechId);
		const data = getAvatarSpeechData(speechId);
		data.generationComplete = false;
		data.attempt += 1;

		if (data.attempt <= 2 && simPausedRef.current === false) {
			emitSynthesizeSpeech(data);
		}
	}

	/**
	 * handleGptResponseFull is a function that is called when a full GPT response is received.
	 * It logs the full response.
	 * @param {object} functionOutput - The output of the function.
	 */
	function handleGptResponseFull(functionOutput, gptId, promptLayer_request_id, metadata) {
		const timeStamp = getElapsedTime();
		const deepCopy = JSON.parse(JSON.stringify(functionOutput));
		console.log("gpt-response-full", deepCopy, timeStamp / 1000);

		if (validateString(functionOutput.dialogue)) {
			closingRef.current = functionOutput.is_conversation_over ?? closingRef.current;
		}

		try {
			finishGptEvent(gptId, { functionOutput: deepCopy, promptLayer_request_id, metadata });
			// getAvatarGptData(gptId).finishEvent(deepCopy,promptLayer_request_id,metadata)
		} catch (e) {
			console.error(e);
		}
	}

	/**
	 * handleConnect is a function that is called when a connection is established.
	 * It sets the connection status to 'Connected' and restarts the DeepGram service if the microphone is started.
	 */
	async function handleConnect(socket) {
		console.error("handleConnect", microphoneStarted);
		if (handleConnectCountRef.current !== 0) {
			await stopDeepGram();

			// if (simPausedRef.current) {
			// 	togglePauseSim();
			// }

			await startDeepGram();
		}

		handleConnectCountRef.current += 1;

		// try {
		// 	// posthog?.capture("instage_user_connected", { setup_id, instage_id: localUser.id });
		// 	const result = posthog?.alias(socket.id, localUser.id);
		// 	console.error("posthog alias", result);
		// } catch (error) {
		// 	console.error(error);
		// }
	}

	/**
	 * handleDisconnect is a function that is called when a connection is disconnected.
	 * It sets the connection status to 'Disconnected'.
	 */
	function handleDisconnect() {
		console.error("handleDisconnect");

		try {
			// posthog?.capture("instage_user_disconnected", { setup_id, instage_id: localUser.id });
		} catch (error) {
			console.error(error);
		}
	}

	/**
	 * Toggles the DeepGram service.
	 *
	 * This function checks if the microphone is started. If it is, it stops the DeepGram service.
	 * If it's not, it starts the DeepGram service.
	 *
	 * @async
	 */
	const toggleDeepGram = useCallback(async () => {
		if (microphoneStarted) {
			await stopDeepGram();
		} else {
			startDeepGram();
		}
	}, [microphoneStarted, stopDeepGram, startDeepGram]);

	function pauseSpeech() {
		const data = getCurrentlyPlayingAvatarSpeechData();
		//  console.log("pauseSpeech", data)
		pauseAudio();
		isAvatarTalkingRef.current = false;
		if (data) {
			if (!performanceMode && characterRef.current) {
				characterRef.current.StopSpeech();
			}

			// Store the current playback time

			data.pauseTime = audioContext.current.currentTime;
			// Remove the onended event listener to prevent it from firing
		}
	}

	function resumeSpeech() {
		const data = getCurrentlyPlayingAvatarSpeechData();
		console.log("resumeSpeech", data);

		if (data) {
			if (!performanceMode) {
				characterRef.current.ResumeSpeech();
			}
			resumeAudio();
		} else {
			// console.log("WIPE ANIMATION DATA");
			if (!performanceMode) {
				characterRef.current.wipeAnimationData(); // Needed to fix unpause viseme sync
			}

			const chat_history = getChatHistory();
			const last_item = chat_history[chat_history.length - 1];
			if (last_item) {
				if (last_item.role === "user") {
					const transcriptId = getLastTranscriptId();

					// Get the data associated with the transcript ID
					const transcriptData = getUserTranscriptData(transcriptId);
					RequestTextData(transcriptData.transcriptId, 2, true);
				}
			}
		}
	}

	function startSpeakTime() {
		setStartUpdateLoop(true);
		if (!performanceMode) {
			characterRef.current?.setPaused(false);
		}

		startRecordingFull(userMediaStreamRef.current, activeSessionDataRef.current);

		resumeSpeech();
	}

	function resumeSpeakTime(skipSpeech = false) {
		setStartUpdateLoop(true);
		if (!performanceMode) {
			characterRef.current?.setPaused(false);
		}
		if (skipSpeech === false) {
			resumeSpeech();
		}
		if (captureOn) {
			// Only resume recording if capture was on
			resumeRecording();
		}
	}

	function pauseSpeakTime() {
		setStartUpdateLoop(false);
		pauseSpeech();
		if (!performanceMode) {
			characterRef.current?.setPaused(true);
		}
		pauseRecording();
	}

	function stopSpeakTime() {
		setStartUpdateLoop(false);

		stopRecordingFull();
		pauseSpeech();
		if (!performanceMode) {
			characterRef.current?.setPaused(true);
		}
	}

	function setMuteMic(muted) {
		try {
			if (userMediaStreamRef.current) {
				userMediaStreamRef.current.getTracks().forEach((track) => {
					if (track.kind === "audio") {
						// eslint-disable-next-line no-param-reassign
						track.enabled = !muted;
					}
				});
			}
		} catch (error) {
			console.error("Error setting mute:", error);
		}
	}

	// function to pause sim only
	function pauseSim() {
		posthog?.capture("instage_user_paused", { setup_id: setup_type, instage_id: localUser?.id });

		// console.log("pauseSim");
		setSimPaused(true);
		IdleTimeRef.current = getElapsedTime();
		stopDeepGram();
		pauseSpeakTime();
		if (userMediaStreamRef.current && userMediaStreamRef.current.getTracks().length > 0) {
			userMediaStreamRef.current.getTracks().forEach((track) => {
				// eslint-disable-next-line no-param-reassign
				track.enabled = false;
			});
		}
	}

	const toggleCapture = useCallback(async () => {
		setCaptureOn((prevCaptureOn) => {
			if (!prevCaptureOn) {
				startRecordVideo();
				posthog?.capture("instage_user_capture_on", { setup_id: setup_type, instage_id: localUser.id });
			} else {
				stopRecordVideo();
				posthog?.capture("instage_user_capture_off", { setup_id: setup_type, instage_id: localUser.id });
			}
			return !prevCaptureOn;
		});
	}, [setCaptureOn, startRecordVideo, stopRecordVideo]);

	const manualEndSim = useCallback(() => {
		setShowConfirmModal(true);
	}, [setShowConfirmModal]);

	const confirmEndSim = useCallback(() => {
		setShowConfirmModal(false);
		endSim();
	}, [setShowConfirmModal, endSim]);

	const toggleVideo = async () => {
		const db = await initDB();

		if (captureOn) {
			toggleCapture();
			setHasStoppedCapture(true);
		}

		if (videoOn) {
			console.log("toggleVideo turning off video");
			await db.put("simulation_db", false, "videoAccess");
			const videoTrack = userMediaStreamRef.current.getVideoTracks()[0];

			// Stop the track if it's enabled
			videoTrack.stop();

			userMediaStreamRef.current.removeTrack(videoTrack);
			console.log("toggleVideo stopping deepgram");

			await stopDeepGram();
			// console.log("streamRef.current", userMediaStreamRef.current)
			//  console.log("streamRef.current tracks", userMediaStreamRef.current.getTracks())

			await sleep(1000);
			console.log("toggleVideo starting deepgram");

			startDeepGram();
			setVideoOn(false);
			posthog?.capture("instage_user_video_on", { setup_id: setup_type, instage_id: localUser.id });
		} else {
			//  console.log("getting new track")
			await db.put("simulation_db", true, "videoAccess");

			// Get a new video track and replace the existing one if it's disabled
			try {
				await stopDeepGram();

				const newStream = await navigator.mediaDevices.getUserMedia({ video: true });
				const newVideoTrack = newStream.getVideoTracks()[0];

				// Print out userMediaStream tracks before adding the video track
				console.log("Before adding track: ", userMediaStreamRef.current.getTracks());

				userMediaStreamRef.current.addTrack(newVideoTrack);

				// Print out userMediaStream tracks after adding the video track
				console.log("After adding track: ", userMediaStreamRef.current.getTracks());

				if (captureOn) {
					//      stopRecordVideo();
				}
				await sleep(100);
				setVideoOn(true);
				startDeepGram();
				if (captureOn) {
					//  startRecordVideo(userMediaStreamRef.current);
				}
			} catch (error) {
				console.error("Error getting video track:", error);
			}
			posthog?.capture("instage_user_video_off", { setup_id: setup_type, instage_id: localUser.id });
		}
	};

	useEffect(() => {
		if (performanceMode) {
			setTimeout(() => {
				console.warn("Starting Simulation");
				onLoadingDone();
			}, 10000);
		}
	}, []);

	return (
		<>
			<Helmet>
				<title>InStage | Simulation</title>
				<meta name="description" content="InStage Simulation" />
				<meta name="robots" content="noindex" />
			</Helmet>

			<SoftPopup
				show={softPopupText !== ""}
				onHide={() => {
					setSoftPopupText("");
				}}
				Text={softPopupText}
			/>
			<PresentationPopUp
				show={showPresentationPopUp}
				onHide={() => {
					setShowPresentationPopUp(false);
				}}
				setPresentationFinished={setPresentationFinished}
			/>
			{/* {showSocketErrorModal && (
				<SocketErrorModal
					posthog={posthog}
					connectionStatus={connectionStatus}
					deepgramStatus={deepgramStatus}
					setShowSocketErrorModal={setShowSocketErrorModal}
				/>
			)} */}

			{showConfirmModal && (
				<ConfirmModal
					setShowConfirmModal={setShowConfirmModal}
					handleModalCancel={() => setShowConfirmModal(false)}
					handleModalConfirm={confirmEndSim}
				/>
			)}

			{showSimEndedModal && (
				<SimEndedModal
					localUser={localUser}
					tenantName={tenantName}
					setShowSimEndedModal={setShowSimEndedModal}
					generateReport={generateReport}
					currentSession={currentSession}
				/>
			)}

			{!simEnded && (
				<>
					<div
						className={styles.simPage}
						style={{
							// padding:
							// 	window.innerWidth > 768 ? (!showSidebar ? "1.25rem 1.25rem 0" : "1.25rem calc(1.25rem + 25%) 0 1.25rem") : "inherit",
							padding: window.innerWidth > 1000 ? "1.25rem 27% 0 2%" : "1.25rem 2% 0",
							zIndex: 1
						}}
					>
						<div className={styles.simPageContainer}>
							{showResumeSimModal && (
								<ResumeSimModal
									audioContext={audioContext}
									initializeAudioContext={initializeAudioContext}
									onLoadingDone={onLoadingDone}
									setShowResumeSimModal={setShowResumeSimModal}
								/>
							)}
							{showEndingModal && (
								<EndingModal
									setShowEndingModal={setShowEndingModal}
									keepGoing={keepGoing}
									endingTimer={endingTimer}
									setKeepGoing={setKeepGoing}
									endSim={endSim}
									togglePauseSim={togglePauseSim}
								/>
							)}
							{simTransition && (
								<div ref={simTransitionViewRef} className={styles.simTransitionView}>
									<p className={styles.simTransitionText}>{pageText.simTransitionTextTranslated}</p>
								</div>
							)}
							{simPaused && (
								<div ref={simTransitionViewRef} className={styles.simTransitionView}>
									<button
										style={{
											background: "none",
											border: "none",
											cursor: "pointer",
											outline: "inherit"
										}}
										type="button"
										className={styles.simTransitionText}
										onClick={() => {
											togglePauseSim();
										}}
									>
										{pageText.Paused}
									</button>
								</div>
							)}

							<div ref={simViewRef} className={styles.simView}>
								<div className={styles.simContainer}>
									{/* {showSocketErrorModal && (
										<div className={styles.simErrorContainer}>
											<LoadingSpinner height={100} width={100} thickness={10} color="#00a9af" />
										</div>
									)} */}
									<div
										style={{
											position: "relative",
											width: "100%",
											height: "100%"
										}}
									>
										<div className={styles.simCharacterContainer}>
											{!performanceMode && (
												<div
													className={styles.simCharacterWindow}
													style={{
														border: isAvatarTalkingRef.current ? "5px solid #DB4F86" : "5px solid #96928a"
														// boxShadow: isAvatarTalkingRef.current ? "0px 3px 12px #00000086" : "none"
													}}
												>
													<div ref={view1} style={{ width: "100%", height: "100%" }}>
														<div className={styles.simCharacterName}>{character.name}</div>
														<DebugComponent
															active={active}
															progress={progress}
															errors={errors}
															item={item}
															loaded={loaded}
															total={total}
															connectionStatus={connectionStatus}
															statusLabel={statusLabel}
														/>
														{isThinking && (
															<div className={styles.speakingInstructionsContainer}>
																<div className={styles.speakingInstructions}>
																	{pageText.Thinking}
																	<span className={styles.speakingStateDots}>
																		<span className={styles.speakingStateDot}>.</span>
																		<span className={styles.speakingStateDot}>.</span>
																		<span className={styles.speakingStateDot}>.</span>
																	</span>
																</div>
															</div>
														)}
													</div>
												</div>
											)}

											<UserVideoComponent
												socket={socket}
												videoOn={videoOn}
												ManualMuteMic={ManualMuteMic}
												userSpeaking={!isAvatarTalkingRef.current}
												isThinking={isThinking}
												setIsThinking={setIsThinking}
												stream={userMediaStreamRef.current}
												userMicText={userMicText}
												canSendDeepgramDataRef={canSendDeepgramDataRef}
												performanceModeEnabled={performanceMode}
											/>
											{performanceMode && (
												<span
													style={{ border: isAvatarTalkingRef.current ? "5px solid #DB4F86" : "5px solid #96928a" }}
													className={styles.performanceModeAvatarPortrait}
												>
													<img
														style={{
															width: "100%",
															height: "100%",
															objectFit: "cover",
															borderRadius: "8px"
														}}
														src={characterPerformanceModeAvatarPic}
														alt="Avatar"
													/>
												</span>
											)}

											{!performanceMode && (
												<Canvas ref={CanvasRef} style={{ position: "absolute" }}>
													<ToneMapping toneMapExp={character.exposureLvl} />
													<View track={view1}>
														<HDR_SETUP hdrMapFilePath={character.hdrmap} />
														<PerspectiveCamera
															makeDefault
															fov={55}
															near={0.1}
															far={1000}
															position={character.cameraPosition}
															rotation={camRotation}
														/>
														{startLoadingCharacter && (
															<Character
																ref={characterRef}
																name={character.name}
																url={character.url}
																onCharacterLoadedCb={onLoadingDone}
																// currentAudioSource=	{currentAudioSource}
																lightSettings={character.lightSettings}
																background={character.background}
																urlAnimations={character.urlAnimations}
																animations={character.animations}
																position={[0, 0, 0]}
																rotation={character.characterRotation}
																characterDict={character}
															/>
														)}
													</View>
												</Canvas>
											)}
										</div>
									</div>
								</div>
							</div>

							{/* <button onClick={playNextAudio}>Start Audio</button> */}
							{/* <div className="progress-bar-container" style={{ top: "-20px", position: "relative", background: "black" }}>
								<div className="progress-bar" style={{ width: `${uploadProgress}%`, background: "blue" }}>
									{uploadProgress}%
								</div>
							</div> */}
						</div>
					</div>

					<SimControls
						runTranslation={runTranslation}
						stream={userMediaStreamRef.current}
						allowSimControls={allowSimControls}
						setAllowSimControls={setAllowSimControls}
						toggleDeepGram={toggleDeepGram}
						microphoneStarted={microphoneStarted}
						showSidebar={showSidebar}
						isMobileDevice={isMobileDevice}
						audioPlaying={audioPlaying}
						videoOn={videoOn}
						toggleVideo={toggleVideo}
						captureOn={captureOn}
						setCaptureOn={setCaptureOn}
						toggleCapture={toggleCapture}
						hasStoppedCapture={hasStoppedCapture}
						setHasStoppedCapture={setHasStoppedCapture}
						togglePauseSim={togglePauseSim}
						simPaused={simPaused}
						setup_type={setup_type}
						presentationFinished={presentationFinished}
						setPresentationFinished={setPresentationFinished}
						manualEndSim={manualEndSim}
						userSpeaking={!isAvatarTalkingRef.current}
						isThinking={isThinking}
						userMicText={pageText.userMicTextTranslated}
						canSendDeepgramDataRef={canSendDeepgramDataRef}
						ManualMuteMic={ManualMuteMic}
						getElapsedTime={getElapsedTime}
						getRemainingTime={getRemainingTime}
						targetTime={activeSessionDataRef?.current?.setup_output?.target_time}
						getChatHistory={getChatHistory}
					/>
				</>
			)}

			{simEnded && (
				<SimFinished
					uploadProgress={uploadProgressState}
					setUploadProgressState={setUploadProgressState}
					currentSession={currentSession}
					setCurrentSession={setCurrentSession}
					showSidebar={showSidebar}
				/>
			)}
		</>
	);
}

export default SimulationStream;
